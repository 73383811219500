<!--新增/添加电子邮箱 @zwy-2021-11-30-->
<template>
  <div class="address_operate_modal" v-if="visibility">
    <el-dialog :title="type=='edit'?'编辑邮箱':'添加邮箱'" v-model="modalVisible" @close="close_dialog" top="25vh">
      <el-form :model="form.data" :rules="rules" ref="ruleForm">
        <el-form-item label="邮箱" :label-width="formLabelWidth" prop="email">
          <el-input v-model="form.data.email" autocomplete="off" placeholder="请输入邮箱号" clearable>
          </el-input>
        </el-form-item>
        <el-form-item label=" " :label-width="formLabelWidth">
          <el-checkbox v-model="form.data.isDefault">设为默认邮箱</el-checkbox>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="close_dialog">取 消</el-button>
          <el-button type="primary" @click="addAddress('ruleForm')" :loading="btnLoading">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
  import { ref, reactive, getCurrentInstance } from "vue";
  import areaData from "../assets/area.json";
  import { ElMessage } from "element-plus";
  export default {
    name: "SldAddressOperation",
    components: {},
    props: ["type", "emailInfo"],
    setup(props, { emit }) {
      const { proxy } = getCurrentInstance();
      const visibility = ref(true);
      const formLabelWidth = "80px";
      const modalVisible = ref(true);
      const form = reactive({ data: {} });
      const areaDataArray = areaData;
      const cascaderProps = { label: "regionName", value: "regionCode" };
      const ruleForm = ref(null);
      const cascaderAddr = ref(null);
      const emailInfo = reactive(props.emailInfo);
      const btnLoading = ref(false)
      const type = ref(props.type)

      if (props.type == "edit") {
        form.data.email = emailInfo.data.email;
        form.data.isDefault = emailInfo.data.isDefault ? true : false; //是否默认地址：1-默认地址，0-非默认地址
      } else {
        form.data.email = "";
        form.data.isDefault = false; //是否默认地址：1-默认地址，0-非默认地址
      }

      const rules = {  //输入校验
        email: [
          { required: true, message: "请输入邮箱", trigger: "change" },
          {
            pattern: /^([a-zA-Z0-9]+[-_.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[-_.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,6}$/,
            message: "请输入正确的邮箱",
            trigger: "change"
          }
        ]
      };

      //增加地址
      const addAddress = () => {
        btnLoading.value = true
        ruleForm.value.validate(valid => {
          if (valid) {
            var param = {
              email: form.data.email.trim(),
              isDefault: form.data.isDefault ? 1 : 0
            };
            var url;
            if (props.type == "edit") {
              url = "v3/member/front/memberInvoiceEmail/update";
              param.emailId = emailInfo.data.emailId
            } else if (props.type == "add") {
              url = "v3/member/front/memberInvoiceEmail/add";
            }
            proxy
              .$post(url, param)
              .then(res => {
                if (res.state == 200) {
                  btnLoading.value = false
                  close_dialog();
                  emit("refreshEmail", param, res.data);
                  ElMessage.success(res.msg);
                } else {
                  ElMessage(res.msg);
                  btnLoading.value = false
                }
              })
          } else {
            btnLoading.value = false
            return false;
          }
        });
      };

      const close_dialog = () => {
        emit("closeEmail");
        document.body.style.overflow = 'visible'
      };

      return {
        visibility,
        formLabelWidth,
        modalVisible,
        form,
        areaDataArray,
        cascaderProps,
        rules,
        ruleForm,
        addAddress,
        cascaderAddr,
        close_dialog,
        btnLoading,
        type
      };
    }
  };
</script>

<style lang="scss">
  .el-cascader-menu__wrap {
    height: 204px !important;
  }

  .address_operate_modal {
    .el-dialog {
      width: 600px !important;
    }

    .el-cascader {
      width: 100% !important;
    }

    .el-checkbox {
      .el-checkbox__input {
        margin-top: -4px;
      }
    }

    .el-button--primary {
      color: #fff;
      background-color: $colorMain;
      border-color: $colorMain;
    }

    .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      background-color: $colorMain;
      border-color: $colorMain;
    }

    .el-checkbox__input.is-checked+.el-checkbox__label {
      color: $colorMain;
    }

    .el-button {
      padding: 9px 20px;
    }

    .el-dialog__body {
      padding: 20px;
    }

    .el-form-item {
      margin-bottom: 15px;
    }

    .el-form-item__content {
      position: relative;
    }

    .el-form-item__error {
      position: absolute;
      top: -2px;
      right: 0;
      display: inline-block;
      width: auto;
      z-index: 2;
      background: #fff;
      left: auto;
      padding: 0 3px;
    }

    input::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #ccc;
      font-size: 12px;
    }

    input::-moz-placeholder {
      /* Firefox 19+ */
      color: #ccc;
      font-size: 12px;
    }

    input:-ms-input-placeholder {
      /* IE 10+ */
      color: #ccc;
      font-size: 12px;
    }

    input:-moz-placeholder {
      /* Firefox 18- */
      color: #ccc;
      font-size: 12px;
    }

    textarea::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #ccc;
      font-size: 12px;
    }

    textarea::-moz-placeholder {
      /* Firefox 19+ */
      color: #ccc;
      font-size: 12px;
    }

    textarea:-ms-input-placeholder {
      /* IE 10+ */
      color: #ccc;
      font-size: 12px;
    }

    textarea:-moz-placeholder {
      /* Firefox 18- */
      color: #ccc;
      font-size: 12px;
    }

    .el-input {
      --el-input-focus-border: #e2231a;
    }

    .el-cascader-node.in-active-path {
      color: #e2231a !important;
    }
  }
</style>